<template>
  <div
    class="container-xs"
    style="display: flex; flex-direction: column; height: 100%"
  >
    <AppHeader back-to="/profile/edit" />
    <div style="display: grid">
      <x-label for="email" tag="label" style="margin-top: 2rem">{{
        $t('form.email')
      }}</x-label>
      <form-input
        id="email"
        v-model="forms.profile.inputField"
        type="email"
        required
        @valid="forms.vset.profile.inputField"
      />
    </div>
    <x-button
      id="save"
      type="large"
      style="margin-top: auto"
      :disabled="!forms.vget.profile.form"
      :is-loading="isLoading"
      @click="save"
    >
      {{ $t('form.save') }}
    </x-button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppHeader from '../../components-rf/AppHeader.vue'

export default {
  name: 'EditProfileEmail',
  components: { AppHeader },
  created() {
    if (this.user) this.forms.profile.inputField = this.user.email
  },
  data() {
    return {
      forms: { profile: { inputField: '' } },
      isLoading: false,
    }
  },
  methods: {
    save() {
      this.isLoading = true
      this.$store
        .dispatch('UserModule/updateUser', {
          email: this.forms.profile.inputField,
        })
        .then(() => this.$router.push('/profile/edit'))
        .finally(() => (this.isLoading = false))
    },
  },
  computed: mapState('UserModule', ['user']),
}
</script>
